import { BiUpArrowAlt } from "react-icons/bi";
import React, { useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import CustomContainer from './CustomContainer'
import { Link } from 'react-router-dom'
import { IMAGES } from '../constants'
import { LazyLoadImage } from "react-lazy-load-image-component";

const Header = () => {
    const [expanded, setExpanded] = useState(false);

    const closeNavbar = () => setExpanded(false);
    const handleScroll = () => {
        window.scrollTo(0, 0);
    }
    return (
        <div className='header'>
            <CustomContainer>
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" expanded={expanded}>
                    <Link className='navbar-brand' to='/'>
                        <LazyLoadImage
                            src={IMAGES.footerlogo}
                            effect="blur"
                            className='img-fluid portfolio'
                            alt={'Logo'}
                            onClick={() => setExpanded(false)}
                        />
                    </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="responsive-navbar-nav" onClick={closeNavbar}>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Link className='nav-link' to="/">Home</Link>
                            <Link className='nav-link' to="/about-us">About Us</Link>
                            <Link className='nav-link' to="/portfolio">Portfolio</Link>
                            {/* <Link className='nav-link' to="/animation">Animation</Link> */}
                            <Link className='nav-link' to="/contact-us">Contact </Link>
                            <Link className='nav-link getInto' to="/contact-us">Get In Touch</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </CustomContainer>


            <button type='button' className='goTop' onClick={handleScroll}><BiUpArrowAlt /></button>
        </div>
    )
}

export default Header