const IMAGES = {
    logo: require('./../assets/imgs/logo.png'),
    logoAnimated: require('./../assets/imgs/reverse.webm'),
    footerlogo: require('./../assets/imgs/footerlogo.png'),
    parallax: require('./../assets/imgs/show.webm'),
    before: require('./../assets/imgs/before.webm'),
    after: require('./../assets/imgs/after.webm'),
    loading: require('./../assets/imgs/Loading.webm'),
    avatar: require('./../assets/imgs/avatar.jpg'),
}


const APIurls = {
    // baseUrl: 'http://127.0.0.1:8000/api/',
    baseUrl: 'https://admin.pixelmotus.com/api/',
    // baseUrl: 'https://portal.pixelmotus.com/public/api/',
    getRang: 'get-range',
    postRequest: 'send-request',
    getHomeVideos: 'get-home-data',
    getProjectPortfolio: 'get-project-portfolio',
    postReview: 'add-review',
    urlVerification: 'verify-project',
}


export { IMAGES, APIurls }