import React from 'react'
import { Col, Row } from 'react-bootstrap'

const CustomContainer = ({ children, size = 11 }) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={size + 2} xl={size} xxl={size} className='mx-auto'>
                {children}
            </Col>
        </Row>
    )
}

export default CustomContainer