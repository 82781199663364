import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout';
import Loader from '../components/Loader';

// Lazy loading pages
const Home = lazy(() => import('../pages/home'));
const AboutUs = lazy(() => import('../pages/aboutus'));
const Portfolio = lazy(() => import('../pages/portfolio'));
const ContactUs = lazy(() => import('../pages/contactus'));
const AnimationPage = lazy(() => import('../pages/animation'));
const PortFolioDetail = lazy(() => import('../pages/portfolio/portfolio-detail'));
const Review = lazy(() => import('../pages/review'));

const AllRoutes = () => {
    return (
        <Layout>
            {/* Suspense is required for lazy loading components */}
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/animation" element={<AnimationPage />} />
                    <Route path="/portfolio-detail/:id" element={<PortFolioDetail />} />
                    <Route path="/review/:clientId/:projectId" element={<Review />} />
                </Routes>
            </Suspense>
        </Layout>
    );
};

export default AllRoutes;
