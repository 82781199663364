import { ImBehance2 } from "react-icons/im";
// import { AiFillInstagram } from "react-icons/ai";
// import { FaBehanceSquare } from "react-icons/fa";
import { BsLinkedin, BsBehance, BsInstagram } from "react-icons/bs";
import React from 'react'
import CustomContainer from './CustomContainer'
import { Link } from 'react-router-dom'
import { IMAGES } from '../constants'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Col, Row } from 'react-bootstrap';
const Footer = () => {
    return (
        <div className='footer'>
            <CustomContainer size={10}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <ul className='footer_link'>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/about-us'>About Us</Link></li>
                            <li><Link to='/portfolio'>Portfolio</Link></li>
                            {/* <li><Link to='/animation'>Animation</Link></li> */}
                            <li><Link to='/contact-us'>Contact</Link></li>
                            {/* <li><Link to='/'>Native UGC</Link></li> */}
                        </ul>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <ul className='footer_link_social'>
                            <li><a href='https://www.behance.net/pixelmotus' target="_black"><ImBehance2 /></a></li>
                            <li><a href='https://www.linkedin.com/company/pixel-motus/' target="_black"><BsLinkedin /></a></li>
                            <li><a href='https://www.instagram.com/pixelmotus/' target="_black"><BsInstagram /></a></li>
                        </ul>
                    </Col>
                </Row>
                <hr />
                <div className='subFooter'>

                    <p>© {new Date().getFullYear()} Pixel Motus LLC</p>
                    <LazyLoadImage
                        src={IMAGES.footerlogo}
                        effect="blur"
                        className='img-fluid portfolio'
                        alt={'Logo'}
                    />
                </div>
            </CustomContainer>
        </div>
    )
}

export default Footer