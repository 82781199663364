import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css';  // import css
// slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css';

import AllRoutes from './routes';
import AOS from 'aos';


// 


function App() {

  AOS.init({
    duration: 1000,
  });

  return (
    <AllRoutes />
  );
}

export default App;
